
import { Component, Vue ,Prop} from "vue-property-decorator";
import EnquiryForm from "./EnquiryForm.vue";
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore 
import $ from "jquery";
@Component({
  components: {EnquiryForm},
})
export default class Header extends Vue {
  @Prop() private headerData!: object
  resources = "RESOURCES"
  isSearchBarOpen = false
  headerArr = [{
    "name":"SERVICES",
    "link":"/services"
  },{
    "name":"METHODOLOGY",
    "link":"/methodology"
  },{
    "name":"OUR WORK",
    "link":"/ourwork"
  },{
    "name":"RESOURCES",
    "link":""
  },{
    "name":"COMPANY",
    "link":"/company"
  },{
    "name":"FAQ'S",
    "link":"/faq"
  }]
  resourceArr=[{
    "name":"FREE TEMPLATES",
    "link":"/template"
  },{
    "name":"FREE OPEN SOURCE ANIMATION",
    "link":"/animation"
  },{
    "name":"OUR MASTERPIECE",
    "link":"/ourwork/detail"
  }]
  allSections = [{
    "id":171,
    "routePath":"/"
  },{
    "id":242,
    "routePath":"/methodology"
  },{
    "id":292,
    "routePath":"/ourwork"
  },{
    "id":244,
    "routePath":"/template"
  },{
    "id":274,
    "routePath":"/animation"
  }]
  universalSearch = ""

  get header(){
    return this.headerData
  }
  get isEnquiryFormOpen(){
    return this.$store.state.home.isFormOpen
  }
  get routeName(){
    return this.$route.name
  }
  get searchedTextData(){
      return this.$store.state.home.universalSearchData
  }
  
  changeRoute(param:any){
    if(param){
      this.$router.push({ path: param});
    }
    this.cancelForm();
  }
  onEnter(){
    this.$store.state.home.universalSearchText = this.universalSearch
    this.$store.dispatch('home/universalSearchData')
    this.$router.push('/search');
    this.isSearchBarOpen = false
    this.universalSearch = ""
  }
  openEnquiryForm(){
    this.$store.state.home.isFormOpen = true
  }
  showLinks(){
    this.$store.state.home.universalSearchText = this.universalSearch
    this.$store.dispatch('home/universalSearchData')
  }
  openResult(data:any){
    let word = ""
    if(data.subtype == "animation"){
      word = "/animation"
    }else if(data.subtype == "masterpiece"){
      word = "/ourwork"
    }else if(data.subtype == "page"){
      for(let i=0;i<this.allSections.length;i++){
        if(data.id == this.allSections[i].id){
          word = this.allSections[i].routePath
        }
      }
    }
    this.$router.push(word)
    this.isSearchBarOpen = false
    this.universalSearch = ""
  }
  closeDropdown(){
    $('.dropdown ul').removeClass('show');
  }
  cancelForm(){
    this.$store.state.home.isFormOpen = false
  }
}
