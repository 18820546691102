
import { Component, Vue, Prop } from "vue-property-decorator";
import VueSlickCarousel from "vue-slick-carousel";
import "vue-slick-carousel/dist/vue-slick-carousel.css";
@Component({
  components: { VueSlickCarousel },
})
export default class HomeBanner extends Vue {
  @Prop() private homeBanner!: object;
  settings = {
    dots: false,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    arrows: true,
    fade: true,
  };
  get homeBannerData() {
    return this.homeBanner;
  }
  changeRoute(data: any) {
    this.$store.state.home.masterpieceFilterSelected = data.banner_categories_label.post_title;
    this.$router.push("/ourWork");
  }
}
