
import { Component, Vue } from "vue-property-decorator";
import Header from "./components/Header.vue";
import Footer from "./components/Footer.vue";
import Home from "./components/homePage/Home.vue";
import FreeDemo from "./components/FreeDemo.vue";
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore 
import $ from "jquery";
@Component({
  components: { Header, Footer, Home, FreeDemo },
})
export default class App extends Vue {
  dismissSecs= 10
  dismissCountDown= 0
  isVisible = false
  mounted() {
    this.$store.dispatch("home/headerFooterData");
  }
  get headerData() {
    return this.$store.state.home.headerFooterData?.header;
  }
  get footerData() {
    return this.$store.state.home.headerFooterData?.footer;
  }
  get freeDemo() {
    return this.$store.state.home.headerFooterData.text_with_cta_button;
  }
  get checkRoutePath() {
    return (
      this.$route.path.includes("ourwork/detail") ||
      this.$route.path.includes("search")
    );
  }
  get errorMsg(){
    return this.$store.state.home.errorMsg;
  }
  countDownChanged(dismissCountDown:any) {
    this.dismissCountDown = dismissCountDown
  }
  get toShow(){
    return this.$store.state.home.downloadModalOpen
  }
  scrollToTop(){
    window.scrollTo({top: 0,behavior: "smooth"});
  }
  created() {
    window.addEventListener("scroll", this.scrollFunction);
  }
  destroyed() {
    window.removeEventListener("scroll", this.scrollFunction);
  }
  scrollFunction() {
    $(window).on("scroll", () => {
     var top = (window.pageYOffset )  - ($(this).clientTop || 0);
     this.isVisible = top >100 ? true : false
    });
  }
}
