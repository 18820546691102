
import {Component, Vue ,Prop} from 'vue-property-decorator';

@Component({
  components: {
  },
})
export default class Offer extends Vue {
  @Prop() private offerData!: object;
  get accordianData(){
    return this.offerData
  }
  changeRoute(data:any){
    this.$store.state.home.serviceDetailId = data.select_post.ID
    this.$router.push('/services/detail/'+data.select_post.ID)
    this.$store.dispatch('home/serviceDetailData')
  }
}
