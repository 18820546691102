import axios from "axios";
import store from "@/store/store";
import apiConfig from "../globalApi.json"
export function createInstance(baseApi=apiConfig.API,timeout=30000){
  const api = axios.create({
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore 
    baseApi,
    timeout
  })
  api.interceptors.response.use((response) => response, (error) => {
    store.state.home.errorMsg = ""
    const {status: statusCode} = error.response ?? {}
    if (statusCode === 404) {
      store.state.home.errorMsg = "Resourse not found, please try again"
    }
    if (statusCode === 408) {
      store.state.home.errorMsg = "Search request timed out, Please try again."
    }
    if (statusCode === 500) {
      store.state.home.errorMsg = "We got an error on the server, please try again"
    }
    if (statusCode === 503) {
      store.state.home.errorMsg = "Search Engine is not responding, please try again"
    }
    throw error;
  });
  return api
}
const commonApi = createInstance()
export default commonApi

