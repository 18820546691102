
import { Component, Vue ,Prop } from "vue-property-decorator";

@Component({
  components: {},
})
export default class Footer extends Vue {
  @Prop() private footerData!: object;
  get footerContact(){
    return this.footerData;
  }
}
