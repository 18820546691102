
import { Component, Vue ,Prop} from "vue-property-decorator";

@Component({
  components: {},
})
export default class Dinos extends Vue {
  @Prop() private dinosData!: []
  get theDinosData(){
    return this.dinosData;
  }
}
