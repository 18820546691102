import Vue from 'vue'
import App from './App.vue'
import store from "@/store/store";
import router from "@/routes";
import BootstrapVue, { IconsPlugin } from "bootstrap-vue";
import VueMeta from 'vue-meta'
import MasonryWall from '@yeger/vue2-masonry-wall'
import PerfectScrollbar from 'vue2-perfect-scrollbar'
import VueObserveVisibility from 'vue-observe-visibility'
import 'vue2-perfect-scrollbar/dist/vue2-perfect-scrollbar.css'
import LottieVuePlayer from '@lottiefiles/vue-lottie-player';
import VueSweetalert2 from 'vue-sweetalert2';
import 'sweetalert2/dist/sweetalert2.min.css';
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
Vue.use(BootstrapVue);
Vue.use(IconsPlugin);
Vue.use(VueMeta)
Vue.use(MasonryWall)
Vue.use(PerfectScrollbar)
Vue.use(VueObserveVisibility)
Vue.use(LottieVuePlayer);
Vue.use(VueSweetalert2);

new Vue({
  store,
  router,
  render: h => h(App),
}).$mount('#app')
