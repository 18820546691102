
import { Component, Vue ,Prop} from "vue-property-decorator";
import VueSlickCarousel from "vue-slick-carousel";
import "vue-slick-carousel/dist/vue-slick-carousel.css";
@Component({
  components: {VueSlickCarousel},
})
export default class OurClients extends Vue {
 @Prop() private clients!: object;
  settings1 = {
    dots: false,
    infinite: true,
    slidesToShow: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    arrows: false,
  };
  get clientData(){
    return this.clients
  }
}
