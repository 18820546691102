
import { Component, Vue ,Prop } from "vue-property-decorator";
@Component({
  components: {},
})
export default class FreeDemo extends Vue {
  @Prop() private freeDemoData!: object;
  get freeData(){
    return this.freeDemoData;
  }
  openEnquiryForm() {
    this.$store.state.home.isFormOpen = true;
  }
}
