
import {Component, Vue ,Prop} from 'vue-property-decorator';

@Component({
  components: {
  }
})
export default class Expertise extends Vue {
  @Prop() private expertiseIcons!: object; 
  get expertiseData(){
    return this.expertiseIcons
  }
  get routePath(){
    return this.$route.path.includes('services')
  }
}
