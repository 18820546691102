import { ActionTree, GetterTree, MutationTree } from "vuex";
import { apiData } from "@/api/result";
import { animationFilter } from "@/api/result";
import { universalSearch } from "@/api/result";


interface State {
  isFormOpen: boolean;
  isHomeLoading: boolean;
  isHeaderFooterLoading: boolean;
  isServiceLoading: boolean;
  isMethodologyLoading: boolean;
  isOurWorkLoading: boolean;
  isTemplateLoading: boolean;
  isMasterPieceLoading: boolean;
  isMasterPieceDetailLoading:boolean
  homeData: [];
  headerFooterData: [];
  serviceData: [];
  methodologyData: [];
  ourWorkData: [];
  templateData: [];
  masterpieceData: [];
  masterpieceDetailData: [];
  companyData:[];
  detailId: number;
  masterpieceFilterSelected: string;
  serviceDetailId: number;
  serviceDetailData: [];
  animationTags: [];
  animationCategory: [];
  animationData:[];
  commonLoader:boolean;
  serviceDetailLoading:boolean;
  isAnimationLoading:boolean
  animationId:[],
  selectedTag:string
  animationTagId:number
  searchedText:string
  universalSearchData:[]
  universalSearchText:string
  isUniversalSearch:boolean
  isUniversalSearchLoading:boolean
  errorMsg:string
  isAnimationFilterLoading:boolean
  downloadModalOpen:boolean
  currentPage:number
  filterApplied:boolean
  animationCategoryLoading:boolean
  animationTagLoading:boolean
  totalResults:number
  isCommonFormVisible:boolean,
  isCompanyLoading:boolean,
  faqData:[],
  isFaqLoading:boolean
}

const state: State = {
  isFormOpen: false,
  isHomeLoading: false,
  isHeaderFooterLoading: false,
  isServiceLoading: false,
  isMethodologyLoading: false,
  isOurWorkLoading: false,
  isTemplateLoading: false,
  isMasterPieceLoading: false,
  homeData: [],
  headerFooterData: [],
  serviceData: [],
  methodologyData: [],
  ourWorkData: [],
  templateData: [],
  masterpieceData: [],
  masterpieceDetailData: [],
  companyData:[],
  detailId: 0,
  masterpieceFilterSelected: "All",
  serviceDetailId: 0,
  serviceDetailData: [],
  animationTags: [],
  animationCategory: [],
  animationData:[],
  commonLoader:false,
  isMasterPieceDetailLoading:false,
  isCompanyLoading:false,
  serviceDetailLoading:false,
  isAnimationLoading:false,
  animationId:[],
  selectedTag:"All Assets",
  animationTagId:0,
  searchedText:"",
  universalSearchData:[],
  universalSearchText:"",
  isUniversalSearch:false,
  isUniversalSearchLoading:false,
  errorMsg:"",
  isAnimationFilterLoading:false,
  downloadModalOpen:false,
  currentPage:1,
  filterApplied:false,
  animationCategoryLoading:false,
  animationTagLoading:false,
  totalResults:0,
  isCommonFormVisible:false,
  faqData:[],
  isFaqLoading:false
};

const mutations = <MutationTree<State>>{
  setHomeData(state, payload: []) {
    state.homeData = payload;
  },
  setHeaderFooterData(state, payload: []) {
    state.headerFooterData = payload;
  },
  setServiceData(state, payload: []) {
    state.serviceData = payload;
  },
  setMethodologyData(state, payload: []) {
    state.methodologyData = payload;
  },
  setOurWorkData(state, payload: []) {
    state.ourWorkData = payload;
  },
  setTemplateData(state, payload: []) {
    state.templateData = payload;
  },
  setMasterpieceData(state, payload: []) {
    state.masterpieceData = payload;
  },
  setMasterpieceDetailData(state, payload: []) {
    state.masterpieceDetailData = payload;
  },
  setCompanyData(state, payload: []) {
    state.companyData = payload;
  },
  setServiceDetailData(state, payload: []) {
    state.serviceDetailData = payload;
  },
  setAnimationTagsData(state, payload: []) {
    state.animationTags = payload;
  },
  setAnimationCategoryData(state, payload: []) {
    state.animationCategory = payload;
  },
  setAnimationData(state, payload: []) {
    state.animationData = payload;
  },
  setAnimationFilterData(state, payload: []) {
    state.animationData = payload;
  },
  setUniversalSearchData(state, payload: []) {
    state.universalSearchData = payload;
  },
  setFaqData(state, payload: []) {
    state.faqData = payload;
  },

};

export const actions = <ActionTree<State, any>>{
  async homePageData({ commit, state, getters }) {
    state.isHomeLoading = true;
    const data = await apiData("pages/171");
    commit("setHomeData", data);
    state.isHomeLoading = false;
  },
  async headerFooterData({ commit, state }) {
    state.isHeaderFooterLoading = true;
    const data = await apiData("navigation");
    commit("setHeaderFooterData", data);
    state.isHeaderFooterLoading = false;
  },
  async serviceData({ commit, state }) {
    state.isServiceLoading = true;
    const data = await apiData("pages/232");
    commit("setServiceData", data);
    state.isServiceLoading = false;
  },
  async methodologyData({ commit, state }) {
    state.isMethodologyLoading = true;
    const data = await apiData("pages/242");
    commit("setMethodologyData", data);
    state.isMethodologyLoading = false;
  },
  async ourWorkData({ commit, state }) {
    state.isOurWorkLoading = true;
    const data = await apiData("pages/292");
    commit("setOurWorkData", data);
    state.isOurWorkLoading = false;
  },
  async templateData({ commit, state }) {
    state.isTemplateLoading = true;
    const data = await apiData("pages/244");
    commit("setTemplateData", data);
    state.isTemplateLoading = false;
  },
  async masterpieceData({ commit, state }) {
    state.isMasterPieceLoading = true;
    const data = await apiData("masterpiece");
    commit("setMasterpieceData", data);
    state.isMasterPieceLoading = false;
  },
  async masterpieceDetailData({ commit, state }) {
    state.isMasterPieceDetailLoading = true
    const data = await apiData("masterpiece/" + state.detailId);
    commit("setMasterpieceDetailData", data);
    state.isMasterPieceDetailLoading = false
  },
  async companyData({ commit, state }) {
    state.isCompanyLoading = true
    const data = await apiData("pages/421");
    commit("setCompanyData", data);
    state.isCompanyLoading = false
  },
  async serviceDetailData({ commit, state }) {
    state.serviceDetailLoading = true
    const data = await apiData("masterpiece/" + state.serviceDetailId);
    commit("setServiceDetailData", data);
    state.serviceDetailLoading = false
  },
  async animationTagData({ commit, state }) {
    state.animationTagLoading = true
    const data = await apiData("animation-tags");
    commit("setAnimationTagsData", data);
    state.animationTagLoading = false
  },
  async animationCategoryData({ commit, state }) {
    state.animationCategoryLoading = true
    const data = await apiData("animation-category");
    commit("setAnimationCategoryData", data);
    state.animationCategoryLoading = false
  },
  async animationData({ commit, state }) {
    state.isAnimationLoading = true
    const data = await apiData("animation?page="+ state.currentPage);
    commit("setAnimationData", data);
    state.isAnimationLoading = false
  },
  async animationFilterData({ commit, state }) {
    state.isAnimationFilterLoading = true
    const data = await animationFilter(state.animationId,state.animationTagId,state.searchedText,state.currentPage);
    commit("setAnimationFilterData", data);
    state.isAnimationFilterLoading = false
  },
  async universalSearchData({ commit, state }) {
    state.isUniversalSearchLoading = true
    const data = await universalSearch(state.universalSearchText);
    commit("setUniversalSearchData", data);
    state.isUniversalSearchLoading = false
  },
  async faqData({ commit, state }) {
    state.isFaqLoading = true
    const data = await apiData("pages/417");
    commit("setFaqData", data);
    state.isFaqLoading = false
  },
};

const getters = <GetterTree<State, any>>{
  getHomeData(state, getters) {
    return state.homeData;
  },
};
const home = {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};

export default home;
