
import { Component, Vue ,Prop } from "vue-property-decorator";
import VueSlickCarousel from "vue-slick-carousel";
import "vue-slick-carousel/dist/vue-slick-carousel.css";
@Component({
  components: {
    VueSlickCarousel,
  },
})
export default class Logos extends Vue {
  @Prop() private quotesData!: []
  @Prop() private logosData!:[]
  settings = {
    dots: false,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    arrows: false,
  };
  currentIndex = 0
  mounted () {
    if(this.logosData){
      setInterval(this.changeIndex, 3000);
    }
  }
  get displayIndex(){
    return this.currentIndex
  }
  get quotes(){
    return this.quotesData
  }
  get logosImg(){
    return this.logosData
  }
  changeIndex() {
    this.currentIndex = this.currentIndex==2 ?  0  : this.currentIndex + 1; 
  }
}
