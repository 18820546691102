
import { Component, Vue } from "vue-property-decorator";
import axios from "axios";
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore 
import apiConfig from "../globalApi.json"
@Component({
  components: {},
})
export default class EnquiryForm extends Vue {
  fullName = "";
  mobileNumber = "";
  emailAddress = "";
  selected = null;
  nameErr = ""
  mobileErr = ""
  emailErr = ""
  clickedSubmit = false

  get isValidEmail() {
    return /^[^@]+@\w+(\.\w+)+\w$/.test(this.emailAddress);
  }
  get isValidNumber(){
    return /^[0]?[789]\d{9}$/.test(this.mobileNumber);
  }
  get services(){
    return this.$store.state.home.headerFooterData.services
  }

  submitForm(){
    this.clickedSubmit = true
    let form = {
    "full-name": this.fullName,
    "email-address": this.emailAddress,
    "interested-in":this.selected,
    "mobile-number":this.mobileNumber
  }
  const config = {
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  }
  axios.post(apiConfig.CONTACT_API+'contact-forms/387/feedback', form,config)
    .then((res) => {
        //Perform Success Action
        if(res.data.invalid_fields.length == 0){
          this.$store.state.home.isFormOpen = false
          this.fullName = "";
          this.mobileNumber = "";
          this.emailAddress = "";
          this.selected = null;
          this.nameErr = ""
          this.mobileErr = ""
          this.emailErr = ""
          this.$swal.fire({
            icon: 'success',
            title: "<h5 style='color:green'> Your response has been submitted </h5>",
            showConfirmButton: false,
            timer: 1500,
          })
        }else{
          for(let i=0;i<res.data.invalid_fields.length;i++){
            if(res.data.invalid_fields[i].field == "full-name"){
              this.nameErr = res.data.invalid_fields[i].message
            }else if(res.data.invalid_fields[i].field == "email-address"){
              this.emailErr = res.data.invalid_fields[i].message
            }else if(res.data.invalid_fields[i].field == "mobile-number"){
              this.mobileErr = res.data.invalid_fields[i].message
            }
          }
        }
    })
    .catch((error) => {
        // error.response.status Check status code
    }).finally(() => {
        //Perform action in always
    });
  }
  cancelForm(){
    this.$store.state.home.isFormOpen = false
  }

}
