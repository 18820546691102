import instance from "./instance"
import store from "@/store/store"
import apiConfig from '../globalApi.json'
const baseApi = apiConfig.API

export const apiData = async (param:string): Promise<void> => {
  const data = await instance.get(baseApi+param)
  if(param.includes('animation?page')){
    store.state.home.totalResults = data.headers["x-wp-total"]
  }
  return data.data as any
}
export const animationFilter = async (filterId:any,tagId:any,search:any,page:any): Promise<void> => {
  if(filterId.length && tagId==0){
    const data = await instance.get(search ? baseApi+"animation?animation-category="+filterId+"&&search="+search+"&&page="+page : baseApi+"animation?animation-category="+filterId+"&&page="+page)
    store.state.home.totalResults = data.headers["x-wp-total"]
    return data.data as any
  }else if(tagId!=0 && filterId.length == 0){
    const data = await instance.get(search ? baseApi+"animation?animation-tags="+tagId+"&&search="+search+"&&page="+page : baseApi+"animation?animation-tags="+tagId+"&&page="+page)
    store.state.home.totalResults = data.headers["x-wp-total"]
    return data.data as any
  }else if(tagId!=0 && filterId.length!=0){
    const data = await instance.get(search ? baseApi+"animation?animation-category="+filterId+"&&animation-tags="+tagId+"&&search="+search+"&&page="+page : baseApi+"animation?animation-category="+filterId+"&&animation-tags="+tagId+"&&page="+page)
    store.state.home.totalResults = data.headers["x-wp-total"]
    return data.data as any
  }else if(tagId==0 && filterId.length==0 && search){
    const data = await instance.get(baseApi+"animation?search="+search+"&&page="+page)
    store.state.home.totalResults = data.headers["x-wp-total"]
    return data.data as any
  }else if(tagId==0 && filterId.length==0 && !search){
    const data = await instance.get(baseApi+"animation?page="+page)
    store.state.home.totalResults = data.headers["x-wp-total"]
    return data.data as any
  }
}
export const universalSearch = async (param:string): Promise<void> => {
  const {data:data} = await instance.get(baseApi+"search?search="+param)
  return data as any
}