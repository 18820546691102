import Vue from "vue";
import VueRouter, { RouterOptions } from "vue-router";

const Home = () => import("@/components/homePage/Home.vue");
const Service = () => import("@/components/servicesPage/Service.vue");
const Methodology = () => import("@/components/methodologyPage/Methodology.vue");
const ServiceInternal = () => import("@/components/servicesPage/ServiceInternal.vue");
const OurWork = () => import("@/components/ourWorkPage/OurWork.vue");
const FreeTemplates = () => import("@/components/resources/FreeTemplates.vue");
const OurWorkDetail = () => import("@/components/ourWorkPage/OurWorkDetail.vue");
const Animation = () => import("@/components/resources/Animation.vue");
const SearchedText = () => import("@/components/homePage/SearchedText.vue");
const Company = () => import("@/components/CompanyPage/Company.vue");
const Faq = () => import("@/components/Faq.vue");

Vue.use(VueRouter);

export const routerOptions: RouterOptions = {
  routes: [
    {
      path: "/",
      name: "HOME",
      component: Home,
    },
    {
      path: "/services",
      name: "SERVICES",
      component: Service,
    },
    {
      path: "/methodology",
      name: "METHODOLOGY",
      component: Methodology,
    },
    {
      path: "/services/detail/:id",
      name: "SERVICES",
      component: ServiceInternal,
    },
    {
      path: "/ourwork",
      name: "OUR WORK",
      component: OurWork,
    },
    {
      path: "/template",
      name: "RESOURCES",
      component: FreeTemplates,
    },
    {
      path: "/ourwork/detail",
      name: "OUR WORK",
      component: OurWorkDetail,
    },
    {
      path: "/animation",
      name: "RESOURCES",
      component: Animation,
    },
    {
      path: "/search",
      component: SearchedText,
    },
    {
      path: "/company",
      name: "COMPANY",
      component: Company,
    },
    {
      path: "/faq",
      name: "FAQ'S",
      component: Faq,
    },
    { 
      path: "*",
      redirect: '/'
    }
  ],
  mode: "history",
};
export default new VueRouter(routerOptions);
