
import { Component, Vue ,Prop } from "vue-property-decorator";
import axios from "axios";
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore 
import apiConfig from "../../globalApi.json"

@Component({
  components: {},
})
export default class Ideal extends Vue {
  @Prop() private idealData!: object;
  @Prop() private strategyForm!: object;
  isModalOpen = false
  currentStep = 1
  fullName=""
  email=""
  business=""
  optionsData = [] as any
  selectedData = [] as any
  clickedSubmit = false
  nextDisabled = true
  get isValidEmail() {
    return /^[^@]+@\w+(\.\w+)+\w$/.test(this.email);
  }
  get idealSectionData(){
    return this.idealData
  }
  get strategyData(){
    return this.strategyForm
  }
  get strategyFormOptions(){   
    const data = Object.entries(this.strategyData)
    for(let i=0;i<data.length;i++){
      if(data[i][0] == "form_options"){
        for(let j=0;j<data[i][1].length;j++){
          this.optionsData.push({
            "option_text":data[i][1][j].option_text,
            "selected":false
          })
        }
      }
    }
    return this.optionsData
  }
  selectedOptions(){
    this.selectedData = []
    for(let i=0;i<this.strategyFormOptions.length;i++){
      if(this.strategyFormOptions[i].selected){
        this.selectedData.push(this.strategyFormOptions[i].option_text)
      }
    }
    this.selectedData = this.selectedData.toString();
  }
  selectOption(index:any){
    this.nextDisabled = true
    this.strategyFormOptions[index].selected = !this.strategyFormOptions[index].selected
    for(let i=0;i<this.strategyFormOptions.length;i++){
      if(this.strategyFormOptions[i].selected){
        this.nextDisabled = false
        break;
      }
    }
  }
  submitForm(){
    this.clickedSubmit = true
    let form = {
      "full-name": this.fullName,
      "email-address": this.email,
      "business-name":this.business,
      "next-strategy":this.selectedData
    }
    const config = {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    }
    axios.post(apiConfig.CONTACT_API+'contact-forms/449/feedback', form,config)
      .then((res) => {
          //Perform Success Action
          if(res.data.invalid_fields.length == 0){
             this.$bvModal.hide('modal-1');
             this.fullName =""
             this.email=""
             this.business=""
             this.selectedData = []
             for(let i=0;i<this.strategyFormOptions.length;i++){
              this.strategyFormOptions[i].selected = false
             }
             this.clickedSubmit =false
             this.changeStep(1)
              this.$swal.fire({
              icon: 'success',
              title: "<h5 style='color:green'> Your response has been submitted </h5>",
              showConfirmButton: false,
              timer: 1500,
            })
          }
      })
      .catch((error) => {
          // error.response.status Check status code
      }).finally(() => {
          //Perform action in always
      });
  }
  changeStep(param:any){
    this.currentStep=param
    if(param == 2){
      this.selectedOptions()
    }
  }
  openModal(){
    this.isModalOpen = true
  }
  closeModal(){
    this.isModalOpen = false
  }
}
