
import { Component, Vue } from "vue-property-decorator";
import OurClients from "./OurClients.vue";
import HomeBanner from "./HomeBanner.vue";
import Dinos from "./Dinos.vue";
import Ideal from "./Ideal.vue";
import Strategy from "./Strategy.vue";
import Expertise from "./Expertise.vue";
import Offer from "./Offer.vue";
import Logos from "./Logos.vue";

@Component({
  components: {
    OurClients,
    HomeBanner,
    Offer ,
    Dinos,
    Ideal,
    Strategy,
    Expertise,
    Logos
  },
    metaInfo() {
      return {
        title: this.$store.state.home.homeData?.yoast_head_json?.title,
        meta: [{ name: "description", content: this.$store.state.home.homeData?.yoast_head_json?.['description']},
        { property: "og:description", content: this.$store.state.home.homeData?.yoast_head_json?.['og_description']},
        { property: "og:locale", content: this.$store.state.home.homeData?.yoast_head_json?.['og_locale']},
        { property: "og:site_name", content: this.$store.state.home.homeData?.yoast_head_json?.['og_site_name']},
        { property: "og:title", content: this.$store.state.home.homeData?.yoast_head_json?.['og_title']},
        { property: "og:type", content: this.$store.state.home.homeData?.yoast_head_json?.['og_type']},
        { property: "og:url", content: this.$store.state.home.homeData?.yoast_head_json?.['og_url']},
        { property: "article:modified_time", content: this.$store.state.home.homeData?.yoast_head_json?.['article_modified_time']},
        { property: "twitter:card", content: this.$store.state.home.homeData?.yoast_head_json?.['twitter_card']}
        ],
        script: [{type: 'application/ld+json',json: {'@context':this.$store.state.home.homeData?.yoast_head_json?.schema?.['@context'],"@graph":this.$store.state.home.homeData?.yoast_head_json?.schema?.['@graph']}}],
      };
    },
})
export default class Home extends Vue {
  idealData = {} as any
  theDinos = [] as any
  public $context: any;
    private get pageTitle() {
    return this.$context.title;
  }
  mounted(){
    this.$store.dispatch('home/homePageData')
    window.scrollTo({top: 0,behavior: "smooth"});
  }
  get homeData(){
    return this.$store.state.home.homeData?.sections
  }
  get homebannerdata(){
    return this.homeData?.banner_section_content
  }
  get ourClients(){
    return this.homeData?.["common-sections"]?.testimonial_section_content
  }
  get offerAccordian(){
    return this.homeData?.post_selection_section
  }
  get expertiseIcon(){
    return this.homeData?.["common-sections"].icon_text
  }
  get strategyData(){
    return this.homeData?.free_strategy_form
  }
  get strategyUse(){
    return this.homeData?.["common-sections"]?.video_text_cta
  }
  get quotes(){
    return this.homeData?.quote_slider
  }
  get logo(){
    return this.homeData?.branding_section?.brand_columns
  }
  get dinosData(){
    if(!this.isLoading){
      this.flexibleData()
    }
    return this.theDinos
  }
  get idealsData(){
    if(!this.isLoading){
      this.flexibleData()
    }
    return this.idealData
  }
  get isLoading(){
    return this.$store.state.home.isHomeLoading
  }
  get isNavLoading(){
    return this.$store.state.home.isHeaderFooterLoading
  }
  flexibleData(){
    for(let i=0;i<this.homeData?.flexible_sections?.length;i++){
      if(this.homeData?.flexible_sections[i]?.acf_fc_layout=="image_with_text_section"){
        this.theDinos = this.homeData?.flexible_sections[i]?.image_with_text_section_content
      }else if(this.homeData?.flexible_sections[i]?.acf_fc_layout=="text_with_cta_section"){
        this.idealData=this.homeData?.flexible_sections[i]?.section_content_text_with_cta_button
      }
    }
  }

}
