
import {Component, Vue ,Prop} from 'vue-property-decorator';
@Component({
  components: {
  }
})
export default class Strategy extends Vue {
  @Prop() private strategyUse!: object;
  get strategyData(){
    return this.strategyUse
  }
  get routePath(){
    return this.$route.path.includes('methodology')
  }
  changeRoute(){
    this.$router.push('/methodology')
  }
}
